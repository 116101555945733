<template>
  <div>
    <GenericBTable
      v-if="!isBusy"
      :items="alternative_names"
      :fields="fields"
      :filterCustom="filterCustom"
      @emitChangeSearchField="changeInputSearch"
      :filter="input_search"
      :pagination="20"
      :show_pagination="true"
      :search_filter="true"
      :allows_crud="allows_crud"
      :display_id="'alternative-names'"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-if="allows_crud" #cell(actions)="row">
        <ButtonSmall
          v-can="'common.change_alternativenames'"
          :click_button="
            () => $bvModal.show(`edit-alternative-name-modal-${row.item.id}`)
          "
          :tooltip_text="`Editar nombre alternativo`"
          :icon="'square'"
        ></ButtonSmall>
        <!-- <button-edit
          v-can="'common.change_alternativenames'"
          @click="$bvModal.show(`edit-alternative-name-modal-${row.item.id}`)"
          v-b-tooltip.v-secondary.noninteractive="'Editar nombre alternativo.'"
        ></button-edit> -->
        <!-- <button-delete
            @click="askForDeleteEgressProfileType(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="'Eliminar nombre alternativo.'"
          ></button-delete> -->
        <b-modal
          :id="`edit-alternative-name-modal-${row.item.id}`"
          title="Editar nombre alternativo"
          size="md"
          hide-footer
        >
          <AlternativeNameForm
            :AlternativeName="row.item"
            @updated="slotUpdatedAlternativeName"
          ></AlternativeNameForm>
        </b-modal>
      </template>
    </GenericBTable>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";
export default {
  name: "AlternativeNames",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameForm: () => import("./AlternativeNameForm"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
    init_search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input_search: this.init_search,
      fields: [
        // {
        //   key: "table_name",
        //   label: "table_name",
        //   sortable: true,
        //   display_column: false,
        // },
        {
          key: "visible_name",
          label: "Nombre singular",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-justify",
          display_column: true,
        },
        {
          key: "visible_name_plural",
          label: "Nombre plural",
          sortable: true,
          tdClass: "text-justify",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "verbose_name",
          label: "Nombre por defecto singular",
          tdClass: "text-justify",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "verbose_name_plural",
          label: "Nombre plural por defecto",
          tdClass: "text-justify",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
      ],
      loading: 0,
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      alternative_names: names.ALTERNATIVE_NAMES,
    }),
    isBusy() {
      if (this.loading != 0) return true;
      return false;
    },
  },
  methods: {
    slotUpdatedAlternativeName(alternative_name) {
      this.$bvModal.hide(`edit-alternative-name-modal-${alternative_name.id}`);
    },
    filterCustom(row) {
      if (
        // this.$filtered(row.table_name, this.input_search) ||
        this.$filtered(row.visible_name, this.input_search) ||
        this.$filtered(row.visible_name_plural, this.input_search) ||
        this.$filtered(row.verbose_name, this.input_search) ||
        this.$filtered(row.verbose_name_plural, this.input_search)
      ) {
        return true;
      } else return false;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
  },
  created() {
    this.sortBy = "visible_name";
    this.loading = 1;
    this.$store.dispatch(names.FETCH_ALTERNATIVE_NAMES).then(() => {
      this.loading -= 1;
    });
  },
};
</script>

<style scoped>
.btn-add-cycle {
  background: var(--primary-color);
  border: none;
  margin-bottom: 0.5em;
  float: right;
}
.add-icon {
  margin-right: -2%;
  font-size: bold;
}
.profiles-container {
  width: 100%;
}
.profiles {
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 0 2em;
  width: 100%;
  font-size: 11pt;
  text-align: center;
}
</style>